import React, { useRef, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  ArrowBackIos,
  Close,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import useOutsideClick from "../hooks/useOutsideClick";
import { useGetWorkerJobs } from "../apiCalls/jobApiCalls";
import moment from "moment";
import WeekSelector from "../components/WeekSelector";

export default function WorkerJobs() {
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const userFilterRef = useRef();
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("-addedOn");
  const [limit, setLimit] = useState("5");
  const [page, setPage] = useState(1);
  const [week, setWeek] = useState(moment().isoWeek());
  const [year, setYear] = useState(moment().isoWeekYear());

  function handleWeekChange({ week, year }) {
    setWeek(week);
    setYear(year);
  }

  useOutsideClick(userFilterRef, () => {
    if (openFilter) setOpenFilter(false);
  });

  const { isLoading: isJobsLoading, data: jobs } = useGetWorkerJobs(
    token,
    search,
    sort,
    limit,
    page,
    week,
    year
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
    setOpenFilter(false);
    setPage(1);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setOpenFilter(false);
    setPage(1);
  };

  const handleClearFilters = () => {
    setSearch("");
    setLimit("");
    setSort("");
    setOpenFilter(false);
    setPage(1);
  };

  return (
    <>
      <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 md:mt-24 min-h-screen">
        <WeekSelector week={week} year={year} onWeekChange={handleWeekChange} />

        <div className="flex justify-between gap-3 md:gap-5 lg:gap-8 xl:gap-10 pt-2">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <div
            ref={userFilterRef}
            className={`${
              openFilter ? "" : "hidden"
            } filter-sideBar w-2/3 max-w-xs md:w-1/3 flex flex-col gap-3 rounded-lg bg-white shadow px-3 py-5 absolute z-40`}
          >
            <div className="close absolute z-20 right-2 top-2">
              <Close onClick={() => setOpenFilter(false)} />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-lg font-medium" htmlFor="">
                Sort
              </label>
              <select
                className="outline-none px-1 py-1"
                value={sort}
                onChange={handleSortChange}
              >
                <option value="">Default Sort</option>
                <option value="title">Name A-Z</option>
                <option value="-title">Name Z-A</option>
                <option value="-addedOn">Most Recent</option>
                <option value="addedOn">Least Recent</option>
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg font-medium" htmlFor="">
                Limit
              </label>
              <select
                className="outline-none px-1 py-1"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="">Default Limit</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <button
              onClick={handleClearFilters}
              className="mt-1 px-4 py-2 font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border border-gray-100 rounded-lg"
            >
              Clear
            </button>
          </div>
          <div>
            <button
              onClick={() => setOpenFilter(!openFilter)}
              className={`${
                openFilter ? "hidden" : ""
              } px-4 md:px-8 py-2 font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border border-gray-100 rounded-lg`}
            >
              Filter
            </button>
          </div>
          <div className="flex px-2 py-2 w-full rounded-lg btn-shadow hover:scale-105 transition-all bg-white items-center">
            <input
              type="text"
              className="px-1 w-full bg-white outline-none"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />

            <div className="text-gray-500">
              <Close className="text-gray-400" onClick={() => setSearch("")} />
            </div>
          </div>
        </div>

        {isJobsLoading ? (
          <Loader />
        ) : (
          <>
            <div className="space-y-5 md:space-y-0 pt-8 pb-20 md:flex md:flex-wrap md:justify-between md:gap-5">
              {jobs.data.data.map((job) => (
                <div
                  key={job._id}
                  className="card hover:scale-105 transition-all ease-in-out bg-white card-shadow rounded-lg p-3 lg:p-4 md:w-full lg:w-5/12 xl:w-5/12"
                >
                  <div className="pb-4">
                    <Link to={`/addHours/${job._id}`}>
                      <div className="desc  gap-3  ">
                        <h2 className="text-lg font-medium tracking-wide text-transparent bg-[#59c3c2] bg-clip-text">
                          {job.title}
                        </h2>
                        <h2 className="text-md font-medium tracking-wide">
                          Foreman: {job.foremanId.name}
                        </h2>
                        <h2 className="text-md font-medium tracking-wide">
                          Date: {moment(job.addedOn).format("MMM DD, YYYY")}
                        </h2>
                      </div>
                    </Link>
                  </div>
                  <div className={`gap-2 text-white flex justify-end`}>
                    <Link to={`/addHours/${job._id}`}>
                      <div className="flex  text-center px-4 lg:px-4 py-2 text-xs items-center font-medium tracking-wide hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-lg">
                        Add Hours
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="wrapper fixed bottom-0 left-0 right-0 w-full bg-white mt-20">
          <div className="pages  flex gap-5 items-center justify-center  py-3">
            {jobs?.data.pagination.previous && (
              <div
                className="left"
                onClick={() => setPage(jobs?.data.pagination.previous?.page)}
              >
                <div className="w-8 h-8 hover:shadow hover:scale-110 transition-all ease-in-out border border-gray-200 rounded-full flex items-center justify-center hover:text-white hover:bg-[#59c3c2]">
                  <ChevronLeft style={{ fontSize: "24" }} />
                </div>
              </div>
            )}
            <div className="numbers flex gap-2">
              <p className="hover:scale-125 transition-all ease-in-out hover:font-bold">
                {page}
              </p>
            </div>
            {jobs?.data.pagination.next && (
              <div
                className="right"
                onClick={() => setPage(jobs?.data.pagination.next?.page)}
              >
                <div className="w-8 h-8 hover:shadow hover:scale-110 transition-all ease-in-out border border-gray-200 rounded-full flex items-center justify-center hover:text-white hover:bg-[#59c3c2]">
                  <ChevronRight style={{ fontSize: "24" }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
