import React from "react";
import { useGetUserDetails } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
  Label,
  Cell,
} from "recharts";
import { formatted } from "../utils";
import moment from "moment";

import { ArrowBackIos } from "@mui/icons-material";
import { useState } from "react";
import { useGetMetrics } from "../apiCalls/jobApiCalls";

function Metrics() {
  const [startDate, setStartDate] = useState(() => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    return oneMonthAgo;
  });
  const [endDate, setEndDate] = useState(new Date());

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const userId = currentUser._id;

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );

  const { isLoading, data } = useGetMetrics(startDate, endDate, token);

  let maxYValue = 1000;
  let minYValue = 0;
  const yticks = [];

  if (data) {
    maxYValue =
      Math.ceil(
        Math.max(...data.data.weeklyData.map((item) => Number(item.Bonus))) /
          1000
      ) * 1000;
    minYValue =
      Math.floor(
        Math.min(...data.data.weeklyData.map((item) => Number(item.Bonus))) /
          1000
      ) * 1000;

    for (let i = minYValue; i <= maxYValue; i += 1000) {
      yticks.push(i);
    }
  }

  const isMonday = (date) => {
    const day = new Date(date).getDay();
    return day === 1;
  };

  const isSunday = (date) => {
    const day = new Date(date).getDay();
    return day === 0;
  };

  const fallbackImage = "/images/avatar.jpg";
  return (
    <>
      {isUserLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5 px-4">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="flex gap-2 py-4 justify-center ">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  calendarStartDay={1}
                  filterDate={isMonday}
                  className="border rounded p-1 w-32"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  calendarStartDay={1}
                  filterDate={isSunday}
                  className="border rounded p-1 w-32"
                />
                {/* <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  className="rounded-md p-1 w-48 border border-gray-300 bg-white"
                /> */}
                {/* <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  className="rounded-md p-1 w-48 border border-gray-300 bg-white"
                /> */}
              </div>
              <div className="py-4 flex gap-4 justify-center items-center">
                <div
                  className="w-16 h-16 border border-gray-200  rounded-full"
                  style={{
                    backgroundImage: `url("${userDetails.data.data.imgUrl}"), url("${fallbackImage}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>

                <div>
                  <div>
                    <label className=" font-medium text-gray-500">Name:</label>
                    <span className="ml-2">{userDetails.data.data.name}</span>
                  </div>
                  <div>
                    <label className=" font-medium text-gray-500">Role:</label>
                    <span className="ml-5">{userDetails.data.data.role}</span>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="flex mb-12 justify-center overflow-x-auto text-center">
                    <BarChart
                      width={560}
                      height={400}
                      data={data.data.weeklyData}
                      margin={{
                        top: 20,
                        right: 20,
                        left: 40,
                        bottom: 50,
                      }}
                    >
                      <CartesianGrid
                        strokeDasharray="3 3"
                        horizontal={true}
                        vertical={false}
                      />
                      <XAxis dataKey="label" angle={-45} textAnchor="end">
                        <Label
                          value="Week"
                          offset={-45} // Adjust offset to move the label further down
                          position="insideBottom"
                        />
                      </XAxis>
                      <YAxis domain={[minYValue, maxYValue]} ticks={yticks}>
                        <Label
                          value="Bonus"
                          angle={-90}
                          position="insideLeft"
                          style={{ textAnchor: "middle" }}
                          offset={-10}
                        />
                      </YAxis>
                      <Tooltip />
                      <ReferenceLine y={0} stroke="#000" />
                      <Bar dataKey="Bonus">
                        {data.data.weeklyData.map((entry, index) => {
                          let color = entry.Bonus >= 0 ? "#59c3c2" : "#ff4d4f";
                          return <Cell key={`cell-${index}`} fill={color} />;
                        })}
                      </Bar>
                    </BarChart>
                  </div>
                  <div className="mb-12 flex flex-col items-center">
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Total Revenue</div>
                      <div className="">
                        {formatted(data.data.totalRevenue)}
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Revenue Per Hour</div>
                      <div className="text-gray-500">
                        ${data.data.revenuePerHour}/hr
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Budget Rate</div>
                      <div className="text-gray-500">
                        {formatted(data.data.totalBudget)}
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Total OT Used</div>
                      <div className="text-gray-500">
                        {data.data.totalOT} hours
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3  justify-between">
                      <div className="">Labor Used</div>
                      <div className="text-red-500">
                        {formatted(data.data.laborUsed)}
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">% of Budget by Labor</div>
                      <div className="text-red-500">
                        {data.data.laborByBudget}%
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Performance Pay</div>
                      <div className="text-teal-500">
                        {formatted(data.data.performancePay)}
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Actual Labor %</div>
                      <div className="text-teal-500">
                        {data.data.laborByRevenue}%
                      </div>
                    </div>
                    <div className="w-full font-bold md:w-2/3 text-center my-2">
                      <hr className="mb-6" />
                      <span>Non Revenue During Same Date Range</span>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Non Revenue Hours</div>
                      <div className="text-gray-500">
                        {data.data.totalfNrh} hours
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Non Revenue Pay</div>
                      <div className="text-gray-500">
                        {formatted(data.data.totalfNrhPay)}
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Non Revenue Hours of Crew</div>
                      <div className="text-gray-500">
                        {data.data.totalwNrh} hours
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Non Revenue Pay of Crew</div>
                      <div className="text-gray-500">
                        {formatted(data.data.totalwNrhPay)}
                      </div>
                    </div>
                    <div className="w-full font-bold md:w-2/3 text-center my-2">
                      <hr className="mb-6" />
                      <span>Effective Hourly Rate</span>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">Base Rate</div>
                      <div className="text-gray-500">
                        ${data.data.baseRate}/hr
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between ">
                      <div className="">Effective Hourly Rate</div>
                      <div className="text-teal-500">
                        ${data.data.effectiveRate}/hr
                      </div>
                    </div>
                    <div className="flex font-semibold w-full md:w-2/3 justify-between">
                      <div className="">% Impact</div>
                      <div className="text-teal-500">{data.data.impact}%</div>
                    </div>
                    <div className="w-full font-bold md:w-2/3 text-center my-2">
                      <hr className="mb-6" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Metrics;
