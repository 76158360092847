import React, { useRef } from "react";
import {
  useCreateJob,
  useGetJobTypes,
  useGetSalesPerson,
} from "../apiCalls/jobApiCalls";
import { useSelector } from "react-redux";
import { ArrowBackIos } from "@mui/icons-material";
import moment from "moment";
import Select from "react-select";
import { useState } from "react";

export default function PostJob() {
  const [selectedOption, setSelectedOption] = useState(null);
  const titleInputElement = useRef();
  const amountInputElement = useRef();
  const typeInputElement = useRef();
  const dateInputElement = useRef();
  const salesInputElement = useRef();
  const noteInputElement = useRef();
  const collectedInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const userId = currentUser.user._id;

  const { data: salesPersons } = useGetSalesPerson(token);
  const { data: jobTypes } = useGetJobTypes(token);

  const {
    mutate: createJobMutate,
    isLoading: isCreateJobLoading,
    isError: isCreateJobError,
    error: createJobError,
  } = useCreateJob();

  let options = [];
  if (jobTypes) {
    options = jobTypes.data.data.map((item) => {
      let title = "";
      if (item.type === "Percentage") {
        title = `${item.title} @ ${item.rate}%`;
      } else {
        title = `${item.title} @ $${item.rate} per ${item.type}`;
      }

      return {
        value: item._id,
        label: title,
      };
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const inputDate = moment(dateInputElement.current?.value);
    const adjustedDate = inputDate.set({ hour: 12, minute: 0, second: 0 });
    const utcDate = adjustedDate.utc().toDate();

    const data = {
      token: token,
      foremanId: userId,
      title: titleInputElement.current?.value,
      amount: amountInputElement.current?.value,
      addedOn: utcDate,
      salesId: salesInputElement.current?.value,
      typeId: selectedOption?.value,
      note: noteInputElement.current?.value,
      collected: collectedInputElement.current?.value,
    };

    createJobMutate(data);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <h1 className="text-xl font-medium text-center pt-10">New Job</h1>
          <form className="pb-8" onSubmit={handleSubmit}>
            <div className="pb-8 pt-8">
              <div className="space-y-3">
                <div className="space-y-2">
                  <label
                    className="text-base font-medium text-gray-500"
                    htmlFor=""
                  >
                    Title of Job
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      name="title"
                      ref={titleInputElement}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base font-medium text-gray-500"
                    htmlFor=""
                  >
                    Job Type
                  </label>
                  <div className="rounded-xl w-full py-2 px-3 flex gap-2 border border-black">
                    {/* <select
                      name="jobtype"
                      className="w-full outline-none"
                      ref={typeInputElement}
                    >
                      {jobTypes?.data.data.map((type) => (
                        <option key={type._id} value={type._id}>
                          {type.type === "Percentage"
                            ? `${type.title} @ ${type.rate}%`
                            : `${type.title} @ $${type.rate} per ${type.type}`}
                        </option>
                      ))}
                    </select> */}

                    <Select
                      name="jobtype"
                      className="w-full"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: "none",
                          boxShadow: "none",
                          fontWeight: "600",
                        }),
                      }}
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base font-medium text-gray-500"
                    htmlFor=""
                  >
                    Items/Amount/Area of Job
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="number"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      name="amount"
                      ref={amountInputElement}
                      step=".01"
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base font-medium text-gray-500"
                    htmlFor=""
                  >
                    Sales Person
                  </label>
                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <select
                      name="workerIds"
                      className="w-full outline-none"
                      ref={salesInputElement}
                    >
                      {salesPersons?.data.data.map((worker) => (
                        <option key={worker._id} value={worker._id}>
                          {worker.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base font-medium text-gray-500"
                    htmlFor=""
                  >
                    Start date
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="date"
                      name="date"
                      className="w-full outline-none bg-white"
                      ref={dateInputElement}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base font-medium text-gray-500"
                    htmlFor=""
                  >
                    Note
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      name="note"
                      ref={noteInputElement}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base font-medium text-gray-500"
                    htmlFor=""
                  >
                    Collected?
                  </label>
                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <select
                      name="workerIds"
                      className="w-full outline-none"
                      ref={collectedInputElement}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                {isCreateJobLoading ? "...Job Starting" : "Start a New Job"}
              </button>
            </div>
            {isCreateJobError && (
              <div className="text-sm font-medium text-red-600 pt-2">
                <p>{createJobError.response.data.error}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
