import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import {
  useAddBudget,
  useDeleteBudget,
  useGetJobDetails,
  useGetJobTypes,
} from "../apiCalls/jobApiCalls";
import { useGetUserDetails } from "../apiCalls/userApiCalls";
import {
  KeyboardArrowRight,
  ExpandMore,
  ArrowBackIos,
} from "@mui/icons-material";
import moment from "moment";
import Select from "react-select";
import { formatted } from "../utils";

export default function AddBudget() {
  const [selectedOption, setSelectedOption] = useState(null);
  const jobTypeInputElement = useRef();
  const amountInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const userId = currentUser.user._id;
  const { jobId } = useParams();

  const { data: jobTypes } = useGetJobTypes(token);

  const { mutate: deleteBudgetMutate, isLoading: isDeleteBudgetLoading } =
    useDeleteBudget();

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );
  const {
    mutate: addBudgetMutate,
    isLoading: isAddBudgetLoading,
    isError: isAddBudgetError,
    error: addBudgetError,
  } = useAddBudget();

  const { isLoading: isJobDetailsLoading, data: jobDetails } = useGetJobDetails(
    jobId,
    token
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      jobId: jobId,
      token: token,
      typeId: selectedOption?.value,
      amount: amountInputElement.current?.value,
    };

    addBudgetMutate(data);
  };

  const handleDelete = (id) => {
    const data = {
      budgetId: id,
      token: token,
    };
    deleteBudgetMutate(data);
  };

  let options = [];
  if (jobTypes) {
    options = jobTypes.data.data.map((item) => {
      let title = "";
      if (item.type === "Percentage") {
        title = `${item.title} @ ${item.rate}%`;
      } else {
        title = `${item.title} @ $${item.rate} per ${item.type}`;
      }

      return {
        value: item._id,
        label: title,
      };
    });
  }

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <h1 className="text-2xl font-medium pb-4">Job Details</h1>
          {isJobDetailsLoading ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-col sm:flex-row sm:items-center justify-between px-6 border border-gray-100 btn-shadow py-3 rounded-xl">
                <div className="">
                  <p className="text-xl">
                    <b>{jobDetails.data.data.title}</b>
                  </p>
                  <p className="">
                    Started on:{" "}
                    {moment(jobDetails.data.data.addedOn).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                  {jobDetails.data.data.typeTitle && (
                    <p className="">Type: {jobDetails.data.data.typeTitle}</p>
                  )}
                  <p className="">
                    {jobDetails.data.data.jobType &&
                    jobDetails.data.data.jobType !== "Percentage"
                      ? `Area/Items: ${jobDetails.data.data.amount} ${jobDetails.data.data.jobType}`
                      : `Amount: ${formatted(jobDetails.data.data.amount)}`}
                  </p>

                  <p className="">
                    Sales Person: {jobDetails.data.data.salesId?.name}
                  </p>
                </div>
                <div className="sm:text-right">
                  <p className="">
                    Initial Budget:{" "}
                    {formatted(
                      (jobDetails.data.data.commissionRate *
                        jobDetails.data.data.amount) /
                        100
                    )}
                  </p>

                  {jobDetails.data.data.totalAdditionalBudget && (
                    <p className="">
                      Additional Budget:{" "}
                      {formatted(
                        jobDetails.data.data.totalAdditionalBudget | 0
                      )}
                    </p>
                  )}

                  <p className="font-semibold">
                    Total Budget:{" "}
                    {formatted(
                      Number(
                        (jobDetails.data.data.commissionRate *
                          jobDetails.data.data.amount) /
                          100
                      ) + Number(jobDetails.data.data.totalAdditionalBudget | 0)
                    )}
                  </p>
                </div>
              </div>

              <div className="space-y-2 py-4">
                <label className="text-lg font-medium text-gray-900" htmlFor="">
                  Add Budget
                </label>
                <div className="flex gap-4 flex-col items-center">
                  <div className="rounded-xl w-full py-2 px-3 flex gap-2 border border-black">
                    <Select
                      name="jobtype"
                      className="w-full"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: "none",
                          boxShadow: "none",
                          fontWeight: "600",
                        }),
                      }}
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                    />
                  </div>
                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="number"
                      name="amount"
                      className="w-full outline-none"
                      ref={amountInputElement}
                      placeholder="Items/Amount/Area of Job"
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  <button
                    onClick={handleSubmit}
                    className="text-white w-1/3 text-center px-4 py-2 md:py-3 mt-2 text-base font-medium tracking-wide  transition-al bg-[#59c3c2] hover:bg-teal-500 border border-gray-100 rounded-xl"
                  >
                    {isAddBudgetLoading ? "... Is Adding" : "Add"}
                  </button>
                  {isAddBudgetError && (
                    <div className="text-sm font-medium text-red-600 pt-2">
                      <p>{addBudgetError.response.data.error}</p>
                    </div>
                  )}
                </div>
              </div>
              {jobDetails.data.data.additionalBudget &&
                jobDetails.data.data.additionalBudget.length > 0 && (
                  <div className="space-y-3 pb-12 pt-8">
                    <h1 className="text-2xl font-bold">Additional Budget</h1>
                    <div
                      className={`card flex flex-col bg-white card-shadow rounded-lg p-4 overflow-x-auto`}
                    >
                      <table className="w-[330px] sm:w-full">
                        <thead>
                          <tr>
                            <th className="text-left">Title</th>
                            <th className="text-center">Total</th>
                            <th className="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobDetails.data.data.additionalBudget.map(
                            (budgetDetail) => (
                              <tr key={budgetDetail._id}>
                                <td className="border-t border-gray-200">
                                  {budgetDetail.type === "Percentage"
                                    ? `${budgetDetail.title} $${budgetDetail.amount} @ ${budgetDetail.rate}%`
                                    : `${budgetDetail.title} ${budgetDetail.amount} ${budgetDetail.type} @ $${budgetDetail.rate} per ${budgetDetail.type}`}
                                </td>

                                <td className="text-right border-t border-gray-200">
                                  {formatted(budgetDetail.total)}
                                </td>
                                <td className="text-right border-t border-gray-200">
                                  <button
                                    onClick={() =>
                                      handleDelete(budgetDetail._id)
                                    }
                                    className="px-1.5 py-0.5 text-xs font-medium tracking-wide border border-red-300 text-red-300 hover:bg-red-100 rounded-lg"
                                  >
                                    {isDeleteBudgetLoading ? "X.." : "X"}
                                  </button>
                                </td>
                              </tr>
                            )
                          )}

                          <tr>
                            <td className="text-right text-gray-600 font-semibold border-t border-gray-200">
                              Total Additional Budget
                            </td>
                            <td className="text-right font-semibold border-t border-gray-200">
                              {formatted(
                                jobDetails.data.data.totalAdditionalBudget
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
