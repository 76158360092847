import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import {
  useAddNRHours,
  useDeleteNRHour,
  useGetNRHours,
} from "../apiCalls/jobApiCalls";
import { useGetUserDetails } from "../apiCalls/userApiCalls";
import {
  KeyboardArrowRight,
  ExpandMore,
  ArrowBackIos,
} from "@mui/icons-material";
import moment from "moment";

export default function NRHours() {
  const [workerHoursDetailsVisibility, setWorkerHoursDetailsVisibility] =
    useState({});

  const workerIdsInputElement = useRef();
  const hoursInputElement = useRef();
  const dateInputElement = useRef();
  const titleInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const userId = currentUser.user._id;

  const { isLoading: isWorkerHoursLoading, data: workerHours } =
    useGetNRHours(token);

  const { mutate: deleteHourMutate, isLoading: isDeleteHourLoading } =
    useDeleteNRHour();

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );
  const {
    mutate: addHoursMutate,
    isLoading: isAddHoursLoading,
    isError: isAddHoursError,
    error: addHoursError,
  } = useAddNRHours();

  const handleSubmit = (event) => {
    event.preventDefault();

    let addedHours = parseFloat(hoursInputElement.current?.value);
    const titleInput = titleInputElement.current?.value;

    const inputDate = moment(dateInputElement.current?.value);
    const adjustedDate = inputDate.set({ hour: 12, minute: 0, second: 0 });
    const utcDate = adjustedDate.utc().toDate();

    const data = {
      token: token,
      title: titleInput,
      workerId: workerIdsInputElement.current?.value,
      hours: addedHours,
      date: utcDate,
    };

    addHoursMutate(data);
  };

  function handleWorkerHoursDetails(workerId) {
    setWorkerHoursDetailsVisibility((prevState) => ({
      ...prevState,
      [workerId]: !prevState[workerId],
    }));
  }

  const handleDelete = (id) => {
    const data = {
      hourId: id,
      token: token,
    };
    deleteHourMutate(data);
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <h1 className="text-2xl font-medium pb-4">Non Revenue Hours</h1>

          {isUserLoading ? (
            <Loader />
          ) : (
            <>
              <div className="space-y-2 py-4">
                <label className="text-lg font-medium text-gray-900" htmlFor="">
                  Add Workers Hours
                </label>
                <div className="flex gap-4 flex-col items-center">
                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <select
                      name="workerIds"
                      className="w-full outline-none"
                      ref={workerIdsInputElement}
                    >
                      <option value="">Select Worker</option>
                      <option value={userId}>Self</option>
                      {userDetails?.data.data.workers.map((worker) => (
                        <option key={worker._id} value={worker._id}>
                          {worker.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      name="txtTitle"
                      className="w-full outline-none"
                      ref={titleInputElement}
                      placeholder="Title e.g Training Time, Paid Warranty Work, Service Visit "
                    />
                  </div>

                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="number"
                      name="hours"
                      className="w-full outline-none"
                      ref={hoursInputElement}
                      placeholder="Total Hours for the day e.g 10"
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>

                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="date"
                      name="date"
                      className="w-full outline-none"
                      ref={dateInputElement}
                      placeholder="mm/dd/yyyy"
                    />
                  </div>
                  <button
                    onClick={handleSubmit}
                    className="text-white w-1/3 text-center px-4 py-2 md:py-3 mt-2 text-base font-medium tracking-wide  transition-al bg-[#59c3c2] hover:bg-teal-500 border border-gray-100 rounded-xl"
                  >
                    {isAddHoursLoading ? "... Is Adding" : "Add"}
                  </button>
                  {isAddHoursError && (
                    <div className="text-sm font-medium text-red-600 pt-2">
                      <p>{addHoursError.response.data.error}</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {isWorkerHoursLoading ? (
            <Loader />
          ) : (
            <div className="space-y-3 pb-8 pt-10">
              <h1 className="text-2xl font-bold pb-4">Non Revenue Hours</h1>

              {workerHours.data.hours.map((worker) => (
                <div key={worker._id}>
                  <div
                    onClick={() => handleWorkerHoursDetails(worker._id)}
                    className="card flex items-center gap-4 bg-white card-shadow rounded-lg p-4"
                  >
                    <div
                      className="w-16 h-16 border border-gray-200  rounded-full"
                      style={{
                        backgroundImage: `url("${worker.workerImg}"), url("${fallbackImage}")`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div className="grow">
                      <h2 className="text-lg font-medium tracking-wide">
                        {worker.workerName}
                      </h2>
                    </div>
                    <div className="text-4xl">
                      {workerHoursDetailsVisibility[worker._id] ? (
                        <ExpandMore
                          style={{ fontSize: "44", color: "#d8d8d8" }}
                        />
                      ) : (
                        <KeyboardArrowRight
                          style={{ fontSize: "44", color: "#d8d8d8" }}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    className={`card flex flex-col bg-white card-shadow rounded-lg p-4 overflow-x-auto ${
                      workerHoursDetailsVisibility[worker._id] ? "" : "hidden"
                    }`}
                  >
                    <table className="w-full min-w-max">
                      <thead>
                        <tr>
                          <th className="text-left">Date</th>
                          <th className="text-left">Title</th>
                          <th className="text-center">Added by</th>
                          <th className="text-center">Hours</th>
                          <th className="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {worker.hourlyDetails.map((hourlyDetail, index) => (
                          <tr key={index}>
                            <td className="border-t border-gray-200">
                              {moment(hourlyDetail.date).format("MMM DD, YYYY")}
                            </td>
                            <td className="border-t border-gray-200">
                              {hourlyDetail.title}
                            </td>
                            <td className="text-center border-t border-gray-200">
                              {hourlyDetail.addedBy}
                            </td>
                            <td className="text-center border-t border-gray-200">
                              {hourlyDetail.hours}
                            </td>
                            <td className="text-right border-t border-gray-200">
                              <button
                                onClick={() => handleDelete(hourlyDetail.id)}
                                className="px-1.5 py-0.5 text-xs font-medium tracking-wide border border-red-300 text-red-300 hover:bg-red-100 rounded-lg"
                              >
                                {isDeleteHourLoading ? "X.." : "X"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
