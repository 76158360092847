import Home from "./pages/Home";
import "./App.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import Jobs from "./pages/Jobs";
import WorkerJobs from "./pages/WorkerJobs";
import PostJob from "./pages/PostJob";
import EditJob from "./pages/EditJob";
import ChangePassword from "./pages/ChangePassword";
import Profile from "./pages/Profile";
import { useSelector } from "react-redux";
import JobDetails from "./pages/JobDetails";
import { ScrollToTop } from "./hooks/ScrollToTop";
import AddHours from "./pages/AddHours";
import AddHoursWorker from "./pages/AddHoursWorker";
import NRHours from "./pages/NonRev";
import WorkerNRH from "./pages/WorkerNRH";
import Worker from "./pages/Worker";
import AddBudget from "./pages/AddBudget";
import Metrics from "./pages/Metrics";

const queryClient = new QueryClient();

function App() {
  const { currentUser } = useSelector((state) => state.userSlice);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        {currentUser?.user.role === "foreman" ? (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/jobDetails/:jobId" element={<JobDetails />} />
              <Route path="/postJob" element={<PostJob />} />
              <Route path="/nrh" element={<NRHours />} />
              <Route path="/editJob/:jobId" element={<EditJob />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/addHours/:jobId" element={<AddHours />} />
              <Route path="/addBudget/:jobId" element={<AddBudget />} />
              <Route path="/metrics" element={<Metrics />} />
            </Routes>
          </>
        ) : currentUser?.user.role === "worker" ? (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Worker />} />
              <Route path="/jobs" element={<WorkerJobs />} />
              <Route path="/nrh" element={<WorkerNRH />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/addHours/:jobId" element={<AddHoursWorker />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/generatePassword" element={<ForgetPassword />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
