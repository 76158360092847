import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import {
  useAddWorkerHours,
  useDeleteWorkerHour,
  useGetWorkerJobDetails,
} from "../apiCalls/jobApiCalls";
import { ArrowBackIos } from "@mui/icons-material";
import moment from "moment";

export default function AddHoursWorker() {
  const hoursInputElement = useRef();
  const dateInputElement = useRef();
  const lunchInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;

  const { jobId } = useParams();

  const { mutate: deleteHourMutate, isLoading: isDeleteHourLoading } =
    useDeleteWorkerHour();

  const {
    mutate: addHoursMutate,
    isLoading: isAddHoursLoading,
    isError: isAddHoursError,
    error: addHoursError,
  } = useAddWorkerHours();

  const { isLoading: isJobDetailsLoading, data: jobDetails } =
    useGetWorkerJobDetails(jobId, token);

  const handleSubmit = (event) => {
    event.preventDefault();

    let addedHours = parseFloat(hoursInputElement.current?.value);
    const lunchInput = lunchInputElement.current?.value;

    if (lunchInput === "30m") {
      addedHours -= 0.5;
    } else if (lunchInput === "1hr") {
      addedHours -= 1;
    }

    const inputDate = moment(dateInputElement.current?.value);
    const adjustedDate = inputDate.set({ hour: 12, minute: 0, second: 0 });
    const utcDate = adjustedDate.utc().toDate();

    const data = {
      jobId: jobId,
      token: token,
      lunch: lunchInput,
      hours: addedHours,
      date: utcDate,
    };

    addHoursMutate(data);
  };

  const handleDelete = (id) => {
    const data = {
      hourId: id,
      token: token,
    };
    deleteHourMutate(data);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <h1 className="text-2xl font-medium pb-4">Job Details</h1>
          {isJobDetailsLoading ? (
            <Loader />
          ) : (
            <>
              <div className="flex items-center justify-between px-6 border border-gray-100 btn-shadow py-3 rounded-xl">
                <div className="">
                  <p className="text-xl">
                    <b>{jobDetails.data.data.job.title}</b>
                  </p>
                  <p className="">
                    Started on:{" "}
                    <b>
                      {moment(jobDetails.data.data.job.addedOn).format(
                        "MMM DD, YYYY"
                      )}
                    </b>
                  </p>
                  <p className="">
                    Foreman: <b>{jobDetails.data.data.job.foremanId.name}</b>
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="space-y-2 py-4">
            <label className="text-lg font-medium text-gray-900" htmlFor="">
              Add Your Hours
            </label>
            <div className="flex gap-4 flex-col items-center">
              <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                <input
                  type="number"
                  name="hours"
                  className="w-full outline-none"
                  ref={hoursInputElement}
                  placeholder="Total Hours for the day e.g 10"
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                <select
                  name="txtLunch"
                  className="w-full outline-none"
                  ref={lunchInputElement}
                >
                  <option value="">No Lunch</option>
                  <option value="30m">30 Minutes</option>
                  <option value="1hr">01 Hour</option>
                </select>
              </div>

              <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                <input
                  type="date"
                  name="date"
                  className="w-full outline-none"
                  ref={dateInputElement}
                  placeholder="mm/dd/yyyy"
                />
              </div>
              <button
                onClick={handleSubmit}
                className="text-white w-1/3 text-center px-4 py-2 md:py-3 mt-2 text-base font-medium tracking-wide  transition-al bg-[#59c3c2] hover:bg-teal-500 border border-gray-100 rounded-xl"
              >
                {isAddHoursLoading ? "... Is Adding" : "Add"}
              </button>
              {isAddHoursError && (
                <div className="text-sm font-medium text-red-600 pt-2">
                  <p>{addHoursError.response.data.error}</p>
                </div>
              )}
            </div>
          </div>

          <div className="space-y-3 pb-8 pt-10">
            <h1 className="text-2xl font-bold pb-4">Job Hours</h1>
            <div>
              <div className="card flex flex-col bg-white card-shadow rounded-lg p-4 overflow-x-auto">
                <table className="w-full min-w-max">
                  <thead>
                    <tr>
                      <th className="text-left">Date</th>
                      <th className="text-center">Added by</th>
                      <th className="text-center">Hours</th>
                      <th className="text-center">Lunch</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobDetails &&
                      jobDetails.data.data.hours.map((hourlyDetail, index) => (
                        <tr key={index}>
                          <td className="border-t border-gray-200">
                            {moment(hourlyDetail.date).format("MMM DD, YYYY")}
                          </td>
                          <td className="text-center border-t border-gray-200">
                            {hourlyDetail.addedBy}
                          </td>
                          <td className="text-center border-t border-gray-200">
                            {hourlyDetail.hours}
                          </td>
                          <td className="text-center border-t border-gray-200">
                            {hourlyDetail.lunch}
                          </td>
                          <td className="text-right border-t border-gray-200">
                            <button
                              onClick={() => handleDelete(hourlyDetail._id)}
                              className="px-1.5 py-0.5 text-xs font-medium tracking-wide border border-red-300 text-red-300 hover:bg-red-100 rounded-lg"
                            >
                              {isDeleteHourLoading ? "X.." : "X"}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
