import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetUserDetails,
  useUpdateUserImage,
} from "../apiCalls/userApiCalls";
import Loader from "../components/Loader";
import { EditOutlined, ArrowBackIos } from "@mui/icons-material";
import moment from "moment";

export default function Profile() {
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const userId = currentUser.user._id;
  const token = currentUser.token;

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );

  const {
    mutate: updateUserImageMutate,
    isLoading: isUpdateUserImageLoading,
    isError: isUpdateUserImageError,
    error: updateUserImageError,
  } = useUpdateUserImage();

  const handleFileChange = (e) => {
    const imageData = {
      token: token,
      userId: userId,
      image: e.target.files[0],
    };
    updateUserImageMutate(imageData);
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      {isUserLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="pt-10 flex flex-col items-center">
                <form action="" className="flex items-end">
                  <div
                    className="w-28 h-28 border border-gray-200  rounded-full"
                    style={{
                      backgroundImage: `url("${userDetails.data.data.imgUrl}"), url("${fallbackImage}")`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>

                  <div className="w-7 h-7 -ml-8  rounded-full bg-blue-400 text-white flex justify-center">
                    <label htmlFor="file">
                      {isUpdateUserImageLoading ? (
                        "..."
                      ) : (
                        <EditOutlined style={{ fontSize: "16" }} />
                      )}
                    </label>
                  </div>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </form>
              </div>

              <form className="pb-4">
                <div className="space-y-3 pt-8">
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Name
                    </label>
                    <div className=" text-lg py-2 px-2 flex gap-2 border-b border-gray-500 ">
                      {userDetails.data.data.name}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Email
                    </label>
                    <div className=" text-lg py-2 px-2 flex gap-2 border-b border-gray-500 ">
                      {userDetails.data.data.email}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Role
                    </label>
                    <div className=" text-lg py-2 px-2 flex gap-2 border-b border-gray-500 ">
                      {userDetails.data.data.role}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Hourly Rate
                    </label>
                    <div className=" text-lg py-2 px-2 flex gap-2 border-b border-gray-500 ">
                      ${userDetails.data.data.hourlyRate}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Overtime Rate
                    </label>
                    <div className=" text-lg py-2 px-2 flex gap-2 border-b border-gray-500 ">
                      ${userDetails.data.data.overtimeRate}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Created At
                    </label>
                    <div className=" text-lg py-2 px-2 flex gap-2 border-b border-gray-500 ">
                      {moment(userDetails.data.data.createdAt).format(
                        "MMM DD, YYYY"
                      )}
                    </div>
                  </div>
                </div>
              </form>

              <Link to="/changePassword">
                <h2 className="font-medium text-end pt-3">Change Password</h2>
              </Link>

              {userDetails.data.data.workers &&
                userDetails.data.data.workers.length > 0 && (
                  <div className="space-y-3 pb-8 pt-10">
                    <h1 className="text-2xl font-medium pb-4">
                      Assigned Workers
                    </h1>

                    {userDetails.data.data.workers.map((worker) => (
                      <div
                        key={worker._id}
                        className="card hover:scale-105 transition-all ease-in-out bg-white card-shadow rounded-lg p-3"
                      >
                        <div className="flex gap-5 items-center py-2">
                          <div
                            className="w-16 h-16 border border-gray-200  rounded-full"
                            style={{
                              backgroundImage: `url("${worker.imgUrl}"), url("${fallbackImage}")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></div>
                          <div className="desc -space-y-1">
                            <h2 className="text-lg font-medium tracking-wide">
                              {worker.name}
                            </h2>
                            <h2 className="text-lg font-light tracking-wide">
                              {worker.role}
                            </h2>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
