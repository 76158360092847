import React, { useEffect, useRef, useState } from "react";
import {
  downloadPDF,
  downloadWorkerPDF,
  useGetWeeklyDetails,
  useGetWorkerDashboard,
  useUpdateBonus,
} from "../apiCalls/jobApiCalls";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import { PieChart, Pie, Cell } from "recharts";
import { useGetUserDetails } from "../apiCalls/userApiCalls";
import WeekSelector from "../components/WeekSelector";
import moment from "moment";
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import { formatted } from "../utils";

export default function Home() {
  const [week, setWeek] = useState(moment().isoWeek());
  const [year, setYear] = useState(moment().isoWeekYear());
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const noteInputElement = useRef();
  const [bonusData, setBonusData] = useState([]);

  const [workerHoursDetailsVisibility, setWorkerHoursDetailsVisibility] =
    useState({});

  const { mutate: updateBonusMutate, isLoading: updateBonusLoading } =
    useUpdateBonus();

  const [foremanShare, setForemanShare] = useState(100);

  const token = currentUser.token;
  const userId = currentUser.user._id;
  let data01 = [
    { name: "Profit", value: 1 },
    { name: "Labor", value: 1 },
    { name: "Non-Revenue", value: 1 },
  ];

  const { isLoading, data } = useGetWorkerDashboard(week, year, token);

  const fallbackImage = "/images/avatar.jpg";

  const colors = ["#59c3c2", "#f87171", "#64748b"];

  function handleWeekChange({ week, year }) {
    setWeek(week);
    setYear(year);
  }

  function handleWorkerHoursDetails(workerId) {
    setWorkerHoursDetailsVisibility((prevState) => ({
      ...prevState,
      [workerId]: !prevState[workerId],
    }));
  }

  return (
    <div className="mt-32 md:mt-28">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 rounded-lg mb-1">
          <div className="card bg-white card-shadow rounded-lg py-4 px-4 md:px-10 flex flex-col items-center">
            <span className="text-lg text-center font-bold pb-2">
              Performance Analysis Period
            </span>
            <WeekSelector
              week={week}
              year={year}
              onWeekChange={handleWeekChange}
            />

            {/* <div className="text-center font-semibold mb-2">Budget rate</div>
            <div className="text-center text-4xl font-semibold mb-4">
              {formatted(data.data.totalIncome)}
            </div>
            <div className="text-center font-semibold mb-2">
              Non Revenue Pay
            </div>
            <div className="text-center text-4xl font-semibold mb-4 text-slate-500">
              {formatted(data.data.nonRevenueExpenses)}
            </div>
            <div className="text-center font-semibold mb-2">Labor used</div>
            <div className="text-center text-4xl font-semibold mb-4 text-red-400">
              {formatted(data.data.totalExpense)}
            </div>
            <div className="text-center font-semibold mb-2">
              Performance Pay
            </div>
            <div className="text-center text-4xl font-semibold mb-4 text-[#59c3c2]">
              {formatted(data.data.totalIncome - data.data.totalExpense)}
            </div> */}
          </div>

          {data &&
            data.data &&
            data.data.hours &&
            data.data.hours.length > 0 && (
              <div className="space-y-2 mt-12">
                {data.data.hours.map((worker) => (
                  <div key={worker.id}>
                    <div
                      //onClick={() => handleWorkerHoursDetails(worker.id)}
                      className="card flex items-center gap-4 bg-white card-shadow rounded-lg p-4"
                    >
                      <div
                        className="w-12 h-12 border border-gray-200  rounded-full"
                        style={{
                          backgroundImage: `url("${worker.foremanImg}"), url("${fallbackImage}")`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <div className="grow">
                        <h2 className="text-lg font-medium tracking-wide">
                          Foreman: {worker.foremanName}
                        </h2>
                        <div className="flex justify-between text-sm">
                          <div>
                            <div>Hours Worked: {worker.totalHours} Hrs</div>
                            <div>Bonus Share: {worker.bonusShare}%</div>
                            <div>
                              Adjusted Rate:{" "}
                              {formatted(worker.adjustedHourlyRate)}/hr
                            </div>
                          </div>
                          <div className="flex flex-col items-end">
                            <div>Earned Pay: {formatted(worker.totalPay)}</div>
                            <div>
                              Bonus Amount: {formatted(worker.bonusAmount)}
                            </div>
                            <div className="text-green-500">
                              <b>Final Pay: {formatted(worker.finalPay)}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        `card flex flex-col bg-white card-shadow rounded-lg p-4 overflow-x-auto `
                        // ${
                        //   workerHoursDetailsVisibility[worker.id] ? "" : "hidden"
                        // }`
                      }
                    >
                      <table className="w-full min-w-max">
                        <thead>
                          <tr>
                            <th className="text-left px-2">Day</th>
                            <th className="text-left px-2">Job</th>
                            <th className="text-center  px-2">Hrs</th>
                            <th className="text-center  px-2">@</th>
                            <th className="text-center  px-2">Pay</th>
                            <th className="text-center  px-2">OT</th>
                            <th className="text-center  px-2">@</th>
                            <th className="text-center  px-2">OT Pay</th>
                            <th className="text-center  px-2">Lunch</th>
                          </tr>
                        </thead>
                        <tbody>
                          {worker.hours.map((hourlyDetail, index) => (
                            <tr key={index}>
                              <td className="border-t border-gray-200 px-2">
                                {moment(hourlyDetail.date).format("dddd")}
                              </td>
                              <td className="border-t border-gray-200 px-2">
                                {hourlyDetail.job}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.normalHours !== 0
                                  ? hourlyDetail.normalHours
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.normalPay !== 0
                                  ? `$${hourlyDetail.currentHourlyRate}`
                                  : ""}
                              </td>
                              <td className="text-center border-t text-red-500 font-bold border-gray-200 px-2">
                                {hourlyDetail.normalPay !== 0
                                  ? `${formatted(hourlyDetail.normalPay)}`
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.overtimeHours !== 0
                                  ? hourlyDetail.overtimeHours
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.overtimePay !== 0
                                  ? `$${hourlyDetail.currentOvertimeRate}`
                                  : ""}
                              </td>
                              <td className="text-center border-t  text-red-500 font-bold border-gray-200 px-2">
                                {hourlyDetail.overtimePay !== 0
                                  ? `${formatted(hourlyDetail.overtimePay)}`
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.lunch}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            )}

          <div className="flex flex-col gap-2  items-center justify-center mt-12">
            <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-gray-300">
              <input
                type="text"
                name=""
                id=""
                placeholder="Note to print"
                className="outline-none w-full text-black placeholder-gray-400"
                ref={noteInputElement}
              />
            </div>
            <button
              id="print-button"
              className="px-4 py-1 mb-12 text-sm font-medium bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-md border-none text-white"
              onClick={() =>
                downloadWorkerPDF(
                  week,
                  year,
                  token,
                  noteInputElement.current?.value
                )
              }
            >
              Print PDF
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
