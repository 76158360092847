import React, { useEffect, useRef, useState } from "react";
import {
  downloadPDF,
  useGetWeeklyDetails,
  useUpdateBonus,
} from "../apiCalls/jobApiCalls";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import { PieChart, Pie, Cell } from "recharts";
import { useGetUserDetails } from "../apiCalls/userApiCalls";
import WeekSelector from "../components/WeekSelector";
import moment from "moment";
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import { formatted } from "../utils";

export default function Home() {
  const [week, setWeek] = useState(moment().isoWeek());
  const [year, setYear] = useState(moment().isoWeekYear());
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const noteInputElement = useRef();
  const [bonusData, setBonusData] = useState([]);

  const [workerHoursDetailsVisibility, setWorkerHoursDetailsVisibility] =
    useState({});

  const { mutate: updateBonusMutate, isLoading: updateBonusLoading } =
    useUpdateBonus();

  const [foremanShare, setForemanShare] = useState(100);

  const token = currentUser.token;
  const userId = currentUser.user._id;
  let data01 = [
    { name: "Profit", value: 1 },
    { name: "Labor", value: 1 },
    { name: "Non-Revenue", value: 1 },
  ];

  const { isLoading, data } = useGetWeeklyDetails(week, year, token);

  if (data) {
    data01 = [
      {
        name: "Profit",
        value: Number(data.data.totalIncome) - Number(data.data.totalExpense),
      },
      { name: "Labor", value: +data.data.totalExpense },
      { name: "Non-Revenue", value: +data.data.nonRevenueExpenses },
    ];
  }

  useEffect(() => {
    if (data) {
      setForemanShare(data.data.bonusPercent.foremanShare);
      const bon = data.data.bonusPercent.workers;
      setBonusData(bon);
    } else {
      setBonusData([]);
    }
  }, [data]);

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );

  const fallbackImage = "/images/avatar.jpg";

  const colors = ["#59c3c2", "#f87171", "#64748b"];

  function handleWeekChange({ week, year }) {
    setWeek(week);
    setYear(year);
  }

  function handleWorkerHoursDetails(workerId) {
    setWorkerHoursDetailsVisibility((prevState) => ({
      ...prevState,
      [workerId]: !prevState[workerId],
    }));
  }

  const handleInputChange = (workerId, value) => {
    const updatedShares = bonusData.map((worker) =>
      worker.worker === workerId ? { ...worker, share: Number(value) } : worker
    );
    setBonusData(updatedShares);
  };

  const handleBonusSubmit = (e) => {
    e.preventDefault();
    updateBonusMutate({
      token: token,
      week: week,
      year: year,
      split: bonusData,
    });
  };

  return (
    <div className="mt-32 md:mt-28">
      {isUserLoading ? (
        <Loader />
      ) : (
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 md:mt-10 mb-4 card bg-white card-shadow rounded-lg py-4 px-4 flex flex-col items-center">
          <h1 className="text-lg text-center font-bold">My Team</h1>
          <div className="flex flex-wrap my-2 items-center">
            {userDetails.data.data.workers.map((worker) => (
              <div key={worker._id} className="flex flex-col items-center w-24">
                <div
                  className="w-16 h-16 border border-gray-200 rounded-full"
                  style={{
                    backgroundImage: `url("${worker.imgUrl}"), url("${fallbackImage}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <div className="h-10 text-center">
                  <h2 className="font-medium">{worker.name}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 rounded-lg mb-1">
          <div className="card bg-white card-shadow rounded-lg py-4 px-4 md:px-10 flex flex-col items-center">
            <span className="text-lg text-center font-bold pb-2">
              Performance Analysis Period
            </span>
            <WeekSelector
              week={week}
              year={year}
              onWeekChange={handleWeekChange}
            />

            {!(
              data01[0].value === 0 &&
              data01[1].value === 0 &&
              data01[2].value === 0
            ) ? (
              <PieChart className="z-10" width={300} height={200}>
                <Pie
                  data={data01}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={80}
                >
                  {data01.map((entry, index) => (
                    <Cell key={index} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
              </PieChart>
            ) : null}

            <div className="text-center font-semibold mb-2">Budget rate</div>
            <div className="text-center text-4xl font-semibold mb-4">
              {formatted(data.data.totalIncome)}
            </div>
            <div className="text-center font-semibold mb-2">
              Non Revenue Pay
            </div>
            <div className="text-center text-4xl font-semibold mb-4 text-slate-500">
              {formatted(data.data.nonRevenueExpenses)}
            </div>
            <div className="text-center font-semibold mb-2">Labor used</div>
            <div className="text-center text-4xl font-semibold mb-4 text-red-400">
              {formatted(data.data.totalExpense)}
            </div>
            <div className="text-center font-semibold mb-2">
              Performance Pay
            </div>
            <div className="text-center text-4xl font-semibold mb-4 text-[#59c3c2]">
              {formatted(data.data.totalIncome - data.data.totalExpense)}
            </div>
          </div>
          {data && data.data && data.data.jobs && data.data.jobs.length > 0 && (
            <div className="space-y-2 mt-12">
              <h1 className="text-lg text-center font-bold">
                Jobs for this week!
              </h1>

              {data.data.jobs.map((job) => (
                <div
                  key={job._id}
                  className="card flex flex-col bg-white card-shadow rounded-lg p-2"
                >
                  <div className="font-bold text-lg">{job.title}</div>
                  <div className="text-sm text-gray-400">
                    {moment(job.addedOn).format("dddd MMM DD, YYYY")}
                  </div>
                  {job.typeTitle && (
                    <div className="text-md font-medium tracking-wide">
                      {job.typeTitle}
                    </div>
                  )}
                  <div>
                    <div>
                      <div className="flex justify-between font-medium">
                        <div>
                          {(() => {
                            if (!job.jobType) {
                              return `${job.amount} @ ${job.commissionRate}%`;
                            } else {
                              if (job.jobType === "Percentage") {
                                return `$${job.amount} @ ${job.commissionRate}%`;
                              } else {
                                return `${job.amount} ${job.jobType} @ $${
                                  job.commissionRate / 100
                                }`;
                              }
                            }
                          })()}
                        </div>
                        <div>
                          {formatted(
                            +((job.amount * job.commissionRate) / 100)
                          )}
                        </div>
                      </div>

                      {job.additionalBudget.map((budgetDetail) => (
                        <div
                          className="flex justify-between"
                          key={budgetDetail._id}
                        >
                          <div className="">
                            {budgetDetail.type === "Percentage"
                              ? `${budgetDetail.title} $${budgetDetail.amount} @ ${budgetDetail.rate}%`
                              : `${budgetDetail.title} ${budgetDetail.amount} ${budgetDetail.type} @ $${budgetDetail.rate} per ${budgetDetail.type}`}
                          </div>

                          <div className="">
                            {formatted(budgetDetail.total)}
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-between">
                        <div>Total Budget:</div>
                        <div className="font-bold text-[#59c3c2]">
                          {formatted(
                            +((job.amount * job.commissionRate) / 100) +
                              +(job.totalAdditionalBudget | 0)
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {data &&
            data.data &&
            data.data.hours &&
            data.data.hours.length > 0 && (
              <div className="space-y-2 mt-12">
                <h1 className="text-lg text-center font-bold">Labor details</h1>

                {data.data.hours.map((worker) => (
                  <div key={worker.id}>
                    <div
                      onClick={() => handleWorkerHoursDetails(worker.id)}
                      className="card flex items-center gap-4 bg-white card-shadow rounded-lg p-2"
                    >
                      <div
                        className="w-12 h-12 border border-gray-200  rounded-full"
                        style={{
                          backgroundImage: `url("${worker.workerImgUrl}"), url("${fallbackImage}")`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <div className="grow">
                        <h2 className="text-lg font-medium tracking-wide">
                          {worker.workerName}
                        </h2>
                        <div className="flex justify-between text-sm">
                          <div>
                            <div>Hours Worked: {worker.totalHours} Hrs</div>
                            <div>Bonus Share: {worker.bonusShare}%</div>
                            <div>
                              Adjusted Rate:{" "}
                              {formatted(worker.adjustedHourlyRate)}/hr
                            </div>
                          </div>
                          <div className="flex flex-col items-end">
                            <div>Earned Pay: {formatted(worker.totalPay)}</div>
                            <div>
                              Bonus Amount: {formatted(worker.bonusAmount)}
                            </div>
                            <div className="text-green-500">
                              <b>Final Pay: {formatted(worker.finalPay)}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-4xl">
                        {workerHoursDetailsVisibility[worker.id] ? (
                          <ExpandMore
                            style={{ fontSize: "44", color: "#d8d8d8" }}
                          />
                        ) : (
                          <KeyboardArrowRight
                            style={{ fontSize: "44", color: "#d8d8d8" }}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={`card flex flex-col bg-white card-shadow rounded-lg p-4 overflow-x-auto ${
                        workerHoursDetailsVisibility[worker.id] ? "" : "hidden"
                      }`}
                    >
                      <table className="w-full min-w-max">
                        <thead>
                          <tr>
                            <th className="text-left px-2">Day</th>
                            <th className="text-left px-2">Job</th>
                            <th className="text-center  px-2">Hrs</th>
                            <th className="text-center  px-2">@</th>
                            <th className="text-center  px-2">Pay</th>
                            <th className="text-center  px-2">OT</th>
                            <th className="text-center  px-2">@</th>
                            <th className="text-center  px-2">OT Pay</th>
                            <th className="text-center  px-2">Lunch</th>
                          </tr>
                        </thead>
                        <tbody>
                          {worker.hours.map((hourlyDetail, index) => (
                            <tr key={index}>
                              <td className="border-t border-gray-200 px-2">
                                {moment(hourlyDetail.date).format("dddd")}
                              </td>
                              <td className="border-t border-gray-200 px-2">
                                {hourlyDetail.job}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.normalHours !== 0
                                  ? hourlyDetail.normalHours
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.normalPay !== 0
                                  ? `$${hourlyDetail.currentHourlyRate}`
                                  : ""}
                              </td>
                              <td className="text-center border-t text-red-500 font-bold border-gray-200 px-2">
                                {hourlyDetail.normalPay !== 0
                                  ? `${formatted(hourlyDetail.normalPay)}`
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.overtimeHours !== 0
                                  ? hourlyDetail.overtimeHours
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.overtimePay !== 0
                                  ? `$${hourlyDetail.currentOvertimeRate}`
                                  : ""}
                              </td>
                              <td className="text-center border-t  text-red-500 font-bold border-gray-200 px-2">
                                {hourlyDetail.overtimePay !== 0
                                  ? `${formatted(hourlyDetail.overtimePay)}`
                                  : ""}
                              </td>
                              <td className="text-center border-t border-gray-200 px-2">
                                {hourlyDetail.lunch}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            )}

          <div className="flex flex-col gap-2  items-center justify-center mt-12">
            {!(
              data01[0].value === 0 &&
              data01[1].value === 0 &&
              data01[2].value === 0
            ) ? (
              <>
                <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-gray-300">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Note to print"
                    className="outline-none w-full text-black placeholder-gray-400"
                    ref={noteInputElement}
                  />
                </div>
                <button
                  id="print-button"
                  className="px-4 py-1 text-sm font-medium bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-md border-none text-white"
                  onClick={() =>
                    downloadPDF(
                      week,
                      year,
                      token,
                      noteInputElement.current?.value
                    )
                  }
                >
                  Print PDF
                </button>
              </>
            ) : null}
          </div>

          {data && data.data && data.data.bonusPercent && (
            <form onSubmit={handleBonusSubmit} className=" mt-12 mb-16">
              <h1 className="text-lg text-center font-bold">Bonus Sharing</h1>
              <div className="flex justify-between mb-2">
                <b>Foreman share</b>
                <b className="text-green-500">{foremanShare} %</b>
              </div>

              {bonusData.map((worker) => (
                <div
                  key={worker.worker}
                  className="flex justify-between items-center mb-2"
                >
                  <b>{worker.name}</b>
                  <div>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      max="100"
                      value={worker.share}
                      onChange={(e) =>
                        handleInputChange(worker.worker, e.target.value)
                      }
                      className="border-gray-300 border rounded-lg px-2 py-1 w-14"
                    />{" "}
                    %
                  </div>
                </div>
              ))}

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="px-4 py-1 text-sm font-medium bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-md border-none text-white"
                >
                  Update Bonus Split
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
}
